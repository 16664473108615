<template>
  <div id="DetailContainer">
    <!-- 头部 -->
    <Header></Header>
    <!-- 房屋详细内容 -->
    <div class="homeDeatil">
      <div class="homeDetailContent">
        <div class="homeDetailLeft">
          <div
            style="
              width: 100%;
              height: 3.3rem;
              justify-content: center;
              align-items: center;
              font-size: 2.2rem;
              text-align: center;
              padding-top: 0.8rem;
            "
          >
            <div style="width: 11rem; margin-left:1.5rem;">
              {{ homeInfo.label.content }}
            </div>
          </div>

          <dv-border-box-9
            v-for="item in sensor"
            style="width: 11rem; margin: 1.6rem; height: 3.3rem"
          >
            <div class="sensorTxt" @click="getCedian(item)">
              {{ item.sensor }}
            </div>
          </dv-border-box-9>  
          <div
            @click="yichang"
            style="width: 11rem; height: 3.3rem; font-size: 1.6rem"
          >
            <dv-decoration-9
              style="width: 11rem; margin: 1.6rem; height: 3.3rem"
            >
              安全评估
            </dv-decoration-9>
          </div>
        </div>
        <div class="homeDetailRight">
          <div class="homeDetailRight2" v-show="showData">
            <div class="homeDetailRight2Data" style="margin-right:1rem;">
              <dv-border-box-10>
                <div class="shishi">
                  <div class="shishiChart" ref="shishiChart"></div>
                </div>
                <div v-if="isShowINC" class="INCPosition">
                  <h6>———面内</h6>
                  <h6>- - - - - 面外</h6>
                </div>
                <div v-if="isShowACC" class="INCPosition">
                  <h6>———X方向</h6>
                  <h6>- - - - - Y方向</h6>
                </div>
                <div v-if="isShowWIN" class="WINPosition">
                  <div>风向：</div><p>{{ windDir }}</p>
                </div>
              </dv-border-box-10>
              <dv-border-box-10>
                <Charts
                  v-if="isShow"
                  ref="weekChildComponent"
                  :dayMessage="echartsList.Week"
                  :selectMessage="selectObj"
                  :type="type.week"
                  :dayNum="echartsList.num[0].num"
                  :homeId="homeInfo.id"
                ></Charts>
              </dv-border-box-10>
              
            </div>
            <div class="homeDetailRight2Data">
              <dv-border-box-10>
                <Charts
                  v-if="isShow"
                  ref="dayChildComponent"
                  :dayMessage="echartsList.Day"
                  :selectMessage="selectObj"
                  :type="type.day"
                  :dayNum="echartsList.num[0].num"
                  :homeId="homeInfo.id"
                ></Charts>
              </dv-border-box-10>
              <dv-border-box-10>
                <Charts
                  v-if="isShow"
                  ref="monthChildComponent"
                  :dayMessage="echartsList.Month"
                  :selectMessage="selectObj"
                  :type="type.month"
                  :dayNum="echartsList.num[0].num"
                  :homeId="homeInfo.id"
                ></Charts>
              </dv-border-box-10>
            </div>
          </div>
          <div class="homeDetailRight3" v-show="showError">
            <dv-border-box-10>
              <div class="homeDetailStatus">
                当前安全状态：
                <el-button
                  type="success"
                  icon="el-icon-check"
                  v-show="successStatus"
                  circle
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-close"
                  v-show="closeStatus"
                  circle
                ></el-button>
              </div>
              <div class="homeDetailTable">
                <!-- <h1>异常记录</h1> -->
                <el-table :data="errorInfo" height="480" style="width: 100%">
                  <el-table-column prop="time" label="报警时间" width="220">
                  </el-table-column>
                  <el-table-column prop="data" label="报警信息">
                  </el-table-column>
                </el-table>
              </div>
            </dv-border-box-10>
          </div>
          <div class="homeDetailRight1">
            <dv-border-box-10>
              <div class="scrollable-div" data-simplebar >
                <simplebar>
                  <div v-for="item in txt">{{ item.txtitem }}</div>
                </simplebar>
              </div>
            </dv-border-box-10>
            <dv-border-box-10>
              <EleTable
                v-if="isShowEle"
                :electronInfo="electronInfo"
              ></EleTable>
            </dv-border-box-10>
          </div>
        </div>
      </div>

      <div class="back" >
        <dv-decoration-11
          style="
            width: 11rem;
            height: 3.3rem;
            margin-left:11rem;
            margin-right:3rem;
            line-height: 3.3rem;
          "
          v-if="this.homeInfo.id<10?true:false"
        >
          <div class="backButton" @click="back" >返回</div>
        </dv-decoration-11>
        <dv-decoration-11 style="width: 11rem; height: 3.3rem"  >
          <!-- <el-button :plain="true" :offset="200" @click="openVR">虚拟体验</el-button> -->
          <div class="backButton" :plain="true" :offset="200" @click="openVR" >
            虚拟体验
          </div>
        </dv-decoration-11>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Charts from "@/components/echarts.vue";
import EleTable from "@/components/eleTable.vue";
import { HubConnectionBuilder } from '@microsoft/signalr';
// 第二步，引入滚动条组件和样式并注册之
import simplebar from "simplebar-vue";
import { server } from "websocket";
class WebSocketWrapper {
  constructor(url) {
    this.socket = new WebSocket(url);
  }

  async open() {
    return await new Promise((resolve, reject) => {
      // WebSocket 建立成功回调函数
      this.socket.onopen = resolve;
      // WebSocket 建立失败回调函数
      this.socket.onerror = reject;
    });
  }
  async onmessage() {}

  async send(data) {
    return await new Promise((resolve) => {
      // WebSocket 发送消息成功回调函数
      this.socket.onmessage = (event) => {
        if (event.data) {
          this.successStatus = true;
          this.closeStatus = false;
        }
       // console.log("event.data", event.data);
        // const receivedData = JSON.parse(event.data)
        resolve(JSON.parse(event.data));
      };

      // 发送消息
      this.socket.send(data);
    });
  }

  close() {
    // 关闭 WebSocket
    this.socket.close();
  }
}
export default {
  data() {
    return {
      webSocketUrl:"http://114.132.77.194:8081/ChartHub",
      //webSocketUrl:"https://localhost:7248/ChartHub",
      webSocket: null,
      serverTimer:null,
      // 房屋信息
      homeInfo: JSON.parse(localStorage.getItem("homeInfo")),
      socket: "",
      // 住宅下的指标
      sensor: [],
      // 住宅简介
      txt: [],
      // 存储glb模型路径
      // 控制右边的数据显示与隐藏
      showData: true,
      // 控制右边的异常信息显示与隐藏
      showError: false,
      successStatus: true,
      closeStatus: false,
      checkedNames: [],
      // 存储异常信息
      errorInfo: [],
      // 实时数据的定时器
      time: 0,
      // 实时图表数据对应线条的选中状态
      // Selected: { "测点1": true }
      // 存储日周月的数据
      echartsList: {},
      // 控制父组件还没给组件传值
      isShow: false,
      // 选中的指标
      selectObj: {
        // 选中指标的名字
        selectSensor: null,
        // 选中指标的单位
        selectUnit: null,
        // 选中指标的英文名字
        selectSensorName: null,
        // 当前住宅ID
        selectSensorHouseId:null
      },
      // 上一次点击的时间戳
      lastClickTime: 0,

      type: {
        day: "day",
        week: "week",
        month: "month",
      },
      // 实时风向
      windDir: null,
      // 控制倾角提示的显示与隐藏
      isShowINC: false,
      // 控制方向提示的显示与隐藏
      isShowWIN: false,
      isShowACC:false,
      // pdf文件
      electronInfo: [],
      // 控制电子化文档表格的显示与隐藏
      isShowEle: false,
      isShowModel: false,
      modelUrl: "",
    };
  },
  components: {
    Header,
    Charts,
    EleTable,
    simplebar,
  },
  created() {
    this.getInfo();
    // 获取第一个指标下的数据
     //this.getFirstSensor(this.sensor);
     window.addEventListener('beforeunload', this.handleBeforeUnload);
  },

  mounted() {
    this.chart = this.$echarts.init(this.$refs.shishiChart);
    this.resizeChart();
    window.addEventListener("resize",this.resizeChart)
  },
  computed: {},
  methods: {
    handleBeforeUnload(event) {
      // 你的清理逻辑
      if (this.time !== 0) {
      clearInterval(this.time);
      }
      if (this.webSocket) {
        this.webSocket.invoke('CloseTimer',this.serverTimer).catch(function(err){console.error(err.toString())})
        this.webSocket.stop();
        this.webSocket = null;
      }
    },
    open() {

    },
    error() {
      console.log("连接错误");
    },
    //原地址 /home/selectList?itemId=${this.homeInfo.id}
    getInfo() {
      this.$http
        .get(`/HousePlatform?houseId=${this.homeInfo.id}`)
        .then((res) => {
          if (res.status === 200) {
            //console.log("住宅信息");
            //console.log(res);

            this.txt = res.data.txt;
            this.electronInfo = res.data.pdf;
            this.isShowEle = true;
            if(this.homeInfo.id ===8){
              let mySensor = res.data.sensor;
              const priority = {
                  "温度湿度": 1,
                  "风速风向": 2,
                  "地下水位": 3,
                  "竖向沉降": 4,
                  "倾斜角度": 5,
                  "裂缝宽度": 6,
                  "振动速度": 7
              };
              mySensor.sort((a,b)=>{
                return (priority[a.sensor] || Infinity) - (priority[b.sensor] || Infinity);
              })
              this.sensor = mySensor;
             // console.log("111111111111111111111111",this.sensor)
            }else{
              this.sensor = res.data.sensor;
            }
            
            
            this.getFirstSensor(this.sensor);

            if (res.data.glb[0].glb == "null") {
              this.modelUrl = res.data.glb[0].glb;
             // console.log("this.modelUrl", this.modelUrl);
              this.isShowModel = true;
            }
          }
        });
    },
    // 将时间戳转换成时间格式
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    getFirstSensor(sen) {
      const currentTime = +new Date();
    //  console.log("一开始的数据", sen);
      let firstData = sen[0];
      this.initChart(firstData.name, firstData.sensor, firstData.unit,firstData.table);
    },
    // 点击获取测点数据
    getCedian(e) {

      const currentTime = +new Date();
      if (currentTime - this.lastClickTime < 1200) {
        return;
      }
      this.lastClickTime = currentTime;
      //console.log("鼠标点击");
     // console.log("点击的数据", e);
      this.showData = true;
      this.showError = false;

      if (this.time !== 0) {
        clearInterval(this.time);
        //console.log("清除了定时器");
        this.initChart(e.name, e.sensor, e.unit,e.table);
      } else {
       // console.log(e.sensor);
        this.initChart(e.name, e.sensor, e.unit,e.table);
      }
     // console.log(e);
      //console.log(this.homeInfo);
    },
    // 修改实时数据x轴坐标数据
    modifyxAixs() {
      let xAxis = [];
      let nowTime = new Date().getTime();

      for (let i = 299; i >= 0; i--) {
        let minc = nowTime - i * 1000;
        let minDate = new Date(minc);
        let h =
          minDate.getHours() < 10
            ? "0" + minDate.getHours()
            : minDate.getHours();
        let m =
          minDate.getMinutes() < 10
            ? "0" + minDate.getMinutes()
            : minDate.getMinutes();
        let s =
          minDate.getSeconds() < 10
            ? "0" + minDate.getSeconds()
            : minDate.getSeconds();
        xAxis.push(`${h}:${m}:${s}`);
      }
      this.shishiOption.xAxis.data = xAxis;
    },
    async initChart(sensorName, sensor, unit,tableName) {
      //console.log("this.socket.readySo");
      //console.log(this.socket.readyState);
      //console.log(sensorName);
      if (this.webSocket) {
       // console.log(this.webSocket)
       // console.log("this.serverTimer",this.serverTimer)
        await this.webSocket.invoke('CloseTimer',this.serverTimer).catch(function(err){console.error(err.toString())})
        this.webSocket.stop();
        this.webSocket = null;
      }
      //  使用 signalr插件
      //let url = `wss://cekang.com.cn/websocketPC?sensor=${sensorName}&itemId=${this.homeInfo.id}&time=1`;
      if (!this.webSocket) {
        //this.webSocket = new WebSocketWrapper(url);
         this.webSocket = new HubConnectionBuilder().withUrl(this.webSocketUrl,{withCredentials:false}).build()
      }
      let resData;
       try {
        //await this.webSocket.open();
        this.webSocket.start().then(()=>{
        let webSocketData = {"sensor":sensorName,"itemid":this.homeInfo.id,"tableName":""}
        this.webSocket.invoke('SendMessageAsync',webSocketData).catch(function(err){console.error(err.toString())})
      }).catch(err=>console.log(err))
        
        const response = await new Promise((resolve) => {
          // 接收一次消息后结束
          this.webSocket.on('ReceiveMessage',ReceiveData=>{
            console.log("第一次数据-----------------",ReceiveData)
            resData = ReceiveData
            resolve(resData);
           // console.log(resData.sensor.length,"将ReceiveData赋值给resData")
            if (resData.sensor.length >= 1) {
              this.echartsList = resData.list;
              this.selectObj.selectSensor = sensor;
              this.selectObj.selectUnit = unit;
              this.selectObj.selectSensorName = sensorName;
              this.selectObj.selectSensorHouseId=this.homeInfo.id
              this.isShow = true;
          }})
        });
      } catch (error) {
        console.error(error);
      }
      
      var Color = [
        "#11EE96",
        "#f47920",
        "#FF0033",
        "#d5c59f",
        "#94E61A",
        "#00FFFF",
        "#BB445C",
        "#fcaf17",
        "#ffe600",
        "#CC9966",
        "#90d7ec",
        "#faa755",
      ];
      // 存储x轴实时时间
      var XAxias = [];
      let nowTime = new Date().getTime();

      for (let i = 299; i >= 0; i--) {
        let minc = nowTime - i * 1000;
        let minDate = new Date(minc);
        let h =
          minDate.getHours() < 10
            ? "0" + minDate.getHours()
            : minDate.getHours();
        let m =
          minDate.getMinutes() < 10
            ? "0" + minDate.getMinutes()
            : minDate.getMinutes();
        let s =
          minDate.getSeconds() < 10
            ? "0" + minDate.getSeconds()
            : minDate.getSeconds();
        XAxias.push(`${h}:${m}:${s}`);
      }

      // y轴数据
      var SeriesData = [];
      if(sensorName === "inclination"){
        for (let i = 0; i < resData.num[0].num; i++) {
        let obj = {
          label: {
            show: true,
            textStyle: {
              color: "white",
            },
          },
          name: "INC01",
          type: "line",
          // 设置折线是否平滑
          smooth: true,
          symbol: "none",
          sampling: "lttb",
          lineStyle: {
            normal: {
              color: "#11EE96", // 线条颜色
            },
          },
          //对应x轴的y轴数据
          data: [],
        };
        var i1 = i + 1;
        i1 = i1 < 10 ? "0" + i1 : i1;
        obj.name = "INC" + i1;
        obj.lineStyle.normal.color = Color[i];
        SeriesData.push(obj);
        }
        for (let i = 0; i < resData.num[0].num; i++) {
          let obj = {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "INC01",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#11EE96", // 线条颜色
                type: "dotted",
              },
            },
            //对应x轴的y轴数据
            data: [],
          };
          var i1 = i + 1;
          i1 = i1 < 10 ? "0" + i1 : i1;
          obj.name = "INC" + i1;
          obj.lineStyle.normal.color = Color[i];
          SeriesData.push(obj);
        }
      }else if(sensorName === "acceleration"){
        for (let i = 0; i < resData.num[0].num; i++) {
        let obj = {
          label: {
            show: true,
            textStyle: {
              color: "white",
            },
          },
          name: "ACC01",
          type: "line",
          // 设置折线是否平滑
          smooth: true,
          symbol: "none",
          sampling: "lttb",
          lineStyle: {
            normal: {
              color: "#11EE96", // 线条颜色
            },
          },
          //对应x轴的y轴数据
          data: [],
        };
        var i1 = i + 1;
        i1 = i1 < 10 ? "0" + i1 : i1;
        obj.name = "ACC" + i1;
        obj.lineStyle.normal.color = Color[i];
        SeriesData.push(obj);
        }
        for (let i = 0; i < resData.num[0].num; i++) {
          let obj = {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "ACC01",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#11EE96", // 线条颜色
                type: "dotted",
              },
            },
            //对应x轴的y轴数据
            data: [],
          };
          var i1 = i + 1;
          i1 = i1 < 10 ? "0" + i1 : i1;
          obj.name = "ACC" + i1;
          obj.lineStyle.normal.color = Color[i];
          SeriesData.push(obj);
        }
      }
      
      var Series = [];
      var Selected = {};
      // 判断是什么指标燃弧对应什么名字
      this.isShowINC = false;
      this.isShowWIN = false;
      this.isShowACC= false;
      if (sensorName === "inclination") {
        this.isShowINC = true;
        var Data = [
          "INC01",
          "INC02",
          "INC03",
          "INC04",
          "INC05",
          "INC06",
          "INC07",
          "INC08",
          "INC09",
          "INC10",
          "INC11",
          "INC12",
        ];
        Selected = { INC01: true };
        for (var index = 2; index < resData.num[0].num + 1; index++) {
          index = index < 10 ? "0" + index : index;
          Selected["INC" + index] = false;
        }
        Series = SeriesData;
       // console.log(Series);
      }else if(sensorName === "acceleration") {
        this.isShowACC = true;
        var Data = [
          "ACC01",
          "ACC02",
          "ACC03",
          "ACC04",
          "ACC05",
          "ACC06",
          "ACC07",
          "ACC08",
          "ACC09",
          "ACC10",
          "ACC11",
          "ACC12",
        ];
        Selected = { ACC01: true };
        for (var index = 2; index < resData.num[0].num + 1; index++) {
          index = index < 10 ? "0" + index : index;
          Selected["ACC" + index] = false;
        }
        Series = SeriesData;
       // console.log(Series);
      }
      else {
        Series = [
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT01",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#11EE96", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT02",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#f47920", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT03",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#FF0033", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT04",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#d5c59f", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT05",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#94E61A", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT06",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#00FFFF", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT07",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#BB445C", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT08",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#fcaf17", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT09",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#ffe600", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT10",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#cc9966", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT11",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#90d7ec", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
          {
            label: {
              show: true,
              textStyle: {
                color: "white",
              },
            },
            name: "WAT12",
            type: "line",
            // 设置折线是否平滑
            smooth: true,
            symbol: "none",
            sampling: "lttb",
            lineStyle: {
              normal: {
                color: "#faa755", // 线条颜色
              },
            },
            //对应x轴的y轴数据
            data: [],
          },
        ];
        if (sensorName === "strain") {
          var Data = [
            "STR01",
            "STR02",
            "STR03",
            "STR04",
            "STR05",
            "STR06",
            "STR07",
            "STR08",
            "STR09",
            "STR10",
            "STR11",
            "STR12",
          ];
          Selected = { STR01: true };
          for (var index = 2; index < resData.num[0].num + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["STR" + index] = false;
          }
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "STR" + index;
            return item;
          });
        } else if (sensorName === "water") {
          var Data = [
            "WAT01",
            "WAT02",
            "WAT03",
            "WAT04",
            "WAT05",
            "WAT06",
            "WAT07",
            "WAT08",
            "WAT09",
            "WAT10",
            "WAT11",
            "WAT12",
          ];
          Selected = { WAT01: true };
          for (var index = 2; index < resData.num[0].num + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["WAT" + index] = false;
          }
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "WAT" + index;
            return item;
          });
        } else if (sensorName === "settlement") {
          var Data = [
            "SET01",
            "SET02",
            "SET03",
            "SET04",
            "SET05",
            "SET06",
            "SET07",
            "SET08",
            "SET09",
            "SET10",
            "SET11",
            "SET12",
          ];
          Selected = { SET1: true };
          for (var index = 2; index < resData.num[0].num + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["SET" + index] = false;
          }
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "SET" + index;
            return item;
          });
        
        } else if (sensorName === "wind") {
          this.isShowWIN = true;
          var Data = [
            "WIN01",
            "WIN02",
            "WIN03",
            "WIN04",
            "WIN05",
            "WIN06",
            "WIN07",
            "WIN08",
            "WI9",
            "WIN10",
            "WIN11",
            "WIN12",
          ];
          Selected = { WIN01: true };
          for (var index = 2; index < resData.num[0].num + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["WIN" + index] = false;
          }
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "WIN" + index;
            return item;
          });
        } else if (sensorName === "temperature") {
          var Data = ["TEM01-温度", "TEM01-湿度", "TEM02-温度", "TEM02-湿度"];
          var Selected = { "TEM01-温度": true, "TEM01-湿度": true , "TEM02-温度": false, "TEM02-湿度": false};
          Series = [
            {
              name: "TEM01-温度",
              type: "line",
              yAxisIndex: 0,
              label: {
                show: true,
                textStyle: {
                  color: "white",
                },
              },
              // 设置折线是否平滑
              smooth: true,
              symbol: "none",
              sampling: "lttb",
              lineStyle: {
                normal: {
                  color: "#11EE96", // 线条颜色
                },
              },
              data: [],
            },
            {
              name: "TEM01-湿度",
              type: "line",
              yAxisIndex: 1,
              label: {
                show: true,
                textStyle: {
                  color: "white",
                },
              },
              // 设置折线是否平滑
              smooth: true,
              symbol: "none",
              sampling: "lttb",
              lineStyle: {
                normal: {
                  color: "#FB6272", // 线条颜色
                  type: "dotted",
                },
              },
              data: [],
            },
            {
              name: "TEM02-温度",
              type: "line",
              yAxisIndex: 0,
              label: {
                show: true,
                textStyle: {
                  color: "white",
                },
              },
              // 设置折线是否平滑
              smooth: true,
              symbol: "none",
              sampling: "lttb",
              lineStyle: {
                normal: {
                  color: "red", // 线条颜色
                },
              },
              data: [],
            },
            {
              name: "TEM02-湿度",
              type: "line",
              yAxisIndex: 1,
              label: {
                show: true,
                textStyle: {
                  color: "white",
                },
              },
              // 设置折线是否平滑
              smooth: true,
              symbol: "none",
              sampling: "lttb",
              lineStyle: {
                normal: {
                  color: "gray", // 线条颜色
                  type: "dotted",
                },
              },
              data: [],
            },
            
          ];
        } else if (sensorName === "vibration") {
          var Data = [
            "VIB01",
            "VIB02",
            "VIB03",
            "VIB04",
            "VIB05",
            "VIB06",
            "VIB07",
            "VIB08",
            "VIB09",
            "VIB10",
            "VIB11",
            "VIB12",
          ];
          Selected = { VIB01: true };
          for (var index = 2; index < resData.num[0].num + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["VIB" + index] = false;
          }
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "VIB" + index;
            return item;
          });
        } else {
          var Data = [
            "CRA01",
            "CRA02",
            "CRA03",
            "CRA04",
            "CRA05",
            "CRA06",
            "CRA07",
            "CRA08",
            "CRA09",
            "CRA10",
            "CRA11",
            "CRA12",
          ];
          Selected = { CRA01: true };
          for (var index = 2; index < resData.num[0].num + 1; index++) {
            index = index < 10 ? "0" + index : index;
            Selected["CRA" + index] = false;
          }
          Series = Series.map((item, index) => {
            index = index + 1;
            index = index < 10 ? "0" + index : index;
            item.name = "CRA" + index;
            return item;
          });
        }
      }
      
      for (var i = 0; i < resData.sensorData.length; i++) {
        var split = resData.sensorData[i].data.split("\t");
        for (var j = 0; j < resData.num[0].num; j++) {
          if (split[j].search("_") !== -1) {
            var s = split[j].split("_");
            if (s.length > 0) {
              if(sensorName === "temperature" && resData.num[0].num===2){
               // Series[2*j].data.push(s[1]);
               // Series[2*j+1].data.push(s[0]);
              }else if(sensorName === "settlement" && this.homeInfo.id==8){
                Series[j].data.push(s[1]);
              }else{
                Series[j].data.push(s[0]);
              }
              if (sensorName === "inclination" ||sensorName === "acceleration") {
                let i2 = j + resData.num[0].num;
                Series[i2].data.push(s[1]);
              } else if (sensorName === "wind") {
                this.windDir = s[1];
              } else if (sensorName === "temperature") {
                if(resData.num[0].num==2){
                  Series[2*j ].data.push(s[1]);
                  Series[2*j+1 ].data.push(s[0]); //林鸿儒
                }else {
                  Series[j + 1].data.push(s[1]);
                }
              }
            }
          } else {
            Series[j].data.push(split[j]);
          }
        }
      }
      if (!this.webSocket) {
        //this.webSocket = new WebSocketWrapper(url);
         this.webSocket = new HubConnectionBuilder().withUrl(this.webSocketUrl,{withCredentials:false}).build()
      }
      
      if (this.webSocket) {
        try {
            //await this.webSocket.open();
          let webSocketData = {"sensor":sensorName,"itemid":this.homeInfo.id,"tableName":tableName}
          await this.webSocket.invoke('SendMessageTimerAsync',webSocketData).catch(function(err){console.error(err.toString())})
          await new Promise((resolve) => {
              this.webSocket.on('ReceiveTimerMessage',ReceiveData=>{
                //console.log("实时更新",resData)
                resData = ReceiveData
                this.serverTimer = resData.timerId
                resolve(resData);
              })
            });
        } catch (error) {
          console.error(error);
        }
      }
      sensor = sensor === "风速风向" ? "风速" : sensor;
      let chart;
      let option;
      let optionBottom = resData.num[0].num > 7 ? 10 : 20;
      let optionLeft = resData.num[0].num > 7 ? 50 : 30;
      if (sensorName === "temperature") {
        option = {
          animation: false,
          //折线图标题
          title: {
            text: `${sensor}(${unit})/实时`,
            left: "center",
            top: "10",
            textStyle: {
              lineHeight: 20,
              color: "#ffffff",
              fontFamily: "xkfont",
              fontSize: 22,
            },
          },
          // 折线图线条的颜色
          color: Color.slice(0, resData.num[0].num * 2),
          // 折线图的线条代表意义
          legend: {
            itemWidth: 10, //小圆点的宽度
            itemGap: 6,
            width: 500,
            inactiveColor: "#fff",
            activeColor: "#11EE96",
            textStyle: {
              color: Color.slice(0, resData.num[0].num * 2),
            },
            selectedModel: "single", //折线可多选
            data: Data.slice(0, resData.num[0].num * 2),
            selected: Selected,
            bottom: optionBottom,
            left: optionLeft,
            z: 100,
          },
          // 刻度
          grid: {
            left: "4%",
            right: "4%",
            bottom: "20%",
            top: "20%",
            containLabel: true,
            show: true,
            // borderColor: 'rgba(0, 240, 255, 0.3)'
          },
          // 悬浮图标
          tooltip: {
            show: true,
            trigger: "axis",
            //这一步的效果是当你的光标在左边的时候，悬浮标题在右边，在右边的时候，悬浮标题在左边
            position: function (pos, params, dom, rect, size) {
              var obj = { top: 60 };
              obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
              return obj;
            },
          },
          //x坐标轴
          xAxis: {
            type: "category",
            axisLabel: {
              interval: 48,
              color: "#6495ED",
              color: "#ffffff",

              textStyle: {
                fontSize: 13,
              },
              showMaxLabel: true,
            },
            axisTick: {
              show: true,
              inside: true,
            },
            axisLine: {
              lineStyle: {
                color: "#6495ED",
              },
            },
            //x坐标轴
            data: XAxias,
          },
          //y坐标轴
          yAxis: [
            {
              type: "value",
              name: "温度(℃)",
              position: "left",
              nameTextStyle: {
                color: "#ffffff",
              },
              alignTicksL: true,
              max: 50,
              min: 10,
              axisLabel: {
                textStyle: {
                  fontSize: 13,
                  color: "#ffffff",
                },
                color: "#6495ED",
                formatter: function (value, index) {
                  return value;
                },
              },
              axisTick: {
                inside: true,
                show: true,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#6495ED",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#6495ED",
                },
              },
            },
            {
              type: "value",
              name: "湿度(%)",
              position: "right",
              alignTicksL: true,
              max: 100,
              min: 20,
              nameTextStyle: {
                color: "#ffffff",
              },
              axisLabel: {
                textStyle: {
                  fontSize: 13,
                  color: "#ffffff",
                },
                color: "#6495ED",
                formatter: function (value, index) {
                  return value;
                },
              },
              axisTick: {
                inside: true,
                show: true,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#6495ED",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#6495ED",
                },
              },
            },
          ],
          dataZoom: [
            {
              type: "slider",
              show: false,
            },
          ],
          // 存放数据
          series: Series.slice(0, resData.num[0].num * 2),
        };
        let that = this;
        that.chart = that.$echarts.init(that.$refs.shishiChart, {
          useUTC: false,
        });
        that.chart.clear();
        that.chart.setOption(option);
      //  console.log('option',option);
        
        that.chart
          .off("legendselectchanged")
          .on("legendselectchanged", function (params) {
           // console.log("params");
           // console.log(params);
            Selected = params.selected;
            // var name = params.name
            // if(this.Selected[])
            // this.Selected[name] = selected[name]
            option.legend.selected = Selected;
            that.chart.setOption({
              legend: {
                selected: Selected,
              },
            });
          });
      }else if(this.homeInfo.id==8 && sensorName === "water" )
      {
        option = {
          animation: false,
          //折线图标题
          title: {
            text: `${sensor}(${unit})/实时`,
            left: "center",
            top: "10",
            textStyle: {
              lineHeight: 30,
              color: "#ffffff",
              fontFamily: "xkfont",
              fontSize: 22,
            },
          },
          // 折线图线条的颜色
          color: Color.slice(0, resData.num[0].num),
          // 折线图的线条代表意义
          legend: {
            itemWidth: 10, //小圆点的宽度
            itemGap: 6,
            width: 500,
            inactiveColor: "#fff",
            activeColor: "#11EE96",
            textStyle: {
              color: Color.slice(0, resData.num[0].num),
            },
            selectedModel: "single", //折线可多选
            data: Data.slice(0, resData.num[0].num),
            selected: Selected,
            bottom: optionBottom,
            left: optionLeft,
            z: 100,
          },
          // 刻度
          grid: {
            left: "4%",
            right: "7%",
            bottom: "20%",
            top: "20%",
            containLabel: true,
            show: true,
            // borderColor: 'rgba(0, 240, 255, 0.3)'
          },
          // 悬浮图标
          tooltip: {
            show: true,
            trigger: "axis",
            //这一步的效果是当你的光标在左边的时候，悬浮标题在右边，在右边的时候，悬浮标题在左边
            position: function (pos, params, dom, rect, size) {
              var obj = { top: 60 };
              obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
              return obj;
            },
          },
          //x坐标轴
          xAxis: {
            type: "category",
            axisLabel: {
              interval: 75,
              color: "#6495ED",
              color: "#ffffff",
              textStyle: {
                fontSize: 13,
              },
              showMaxLabel: true,
            },
            axisTick: {
              show: true,
              inside: true,
            },
            axisLine: {
              lineStyle: {
                color: "#6495ED",
              },
            },
            //x坐标轴
            data: XAxias,
          },
          //y坐标轴
          yAxis: {
            type: 'value',
            min: 7,
            max: 14,
            interval: 1,
            axisLabel: {
              
              textStyle: {
                fontSize: 13,
                color: "#ffffff",
              },
              color: "#6495ED",
              formatter: function (value, index) {
                return value;
              },
            },
            axisTick: {
              inside: true,
              show: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#6495ED",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#6495ED",
              },
            },
            type: "value",
            scale: true,
            // min:function(value){return (value.min-(value.max-value.min)).toFixed(4)},
            // max:function(value){return (value.max+(value.max-value.min)).toFixed(4)}
          },
          dataZoom: [
            {
              type: "slider",
              show: false,
            },
          ],
          // 存放数据
          series: Series.slice(0, resData.num[0].num * 2),
        };
        let that = this;
        that.chart = that.$echarts.init(that.$refs.shishiChart, {
          useUTC: false,
        });
        that.chart.clear();
        that.chart.setOption(option);
        that.chart
          .off("legendselectchanged")
          .on("legendselectchanged", function (params) {
          //  console.log("params");
           // console.log(params);
            Selected = params.selected;
            // var name = params.name
            // if(this.Selected[])
            // this.Selected[name] = selected[name]
            option.legend.selected = Selected;
            that.chart.setOption({
              legend: {
                selected: Selected,
              },
            });
          });
      }else if(this.homeInfo.id==8 && sensorName === "settlement" )
      {
        option = {
          animation: false,
          //折线图标题
          title: {
            text: `${sensor}(${unit})/实时`,
            left: "center",
            top: "10",
            textStyle: {
              lineHeight: 30,
              color: "#ffffff",
              fontFamily: "xkfont",
              fontSize: 22,
            },
          },
          // 折线图线条的颜色
          color: Color.slice(0, resData.num[0].num),
          // 折线图的线条代表意义
          legend: {
            itemWidth: 10, //小圆点的宽度
            itemGap: 6,
            width: 500,
            inactiveColor: "#fff",
            activeColor: "#11EE96",
            textStyle: {
              color: Color.slice(0, resData.num[0].num),
            },
            selectedModel: "single", //折线可多选
            data: Data.slice(0, resData.num[0].num),
            selected: Selected,
            bottom: optionBottom,
            left: optionLeft,
            z: 100,
          },
          // 刻度
          grid: {
            left: "4%",
            right: "7%",
            bottom: "20%",
            top: "20%",
            containLabel: true,
            show: true,
            // borderColor: 'rgba(0, 240, 255, 0.3)'
          },
          // 悬浮图标
          tooltip: {
            show: true,
            trigger: "axis",
            //这一步的效果是当你的光标在左边的时候，悬浮标题在右边，在右边的时候，悬浮标题在左边
            position: function (pos, params, dom, rect, size) {
              var obj = { top: 60 };
              obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
              return obj;
            },
          },
          //x坐标轴
          xAxis: {
            type: "category",
            axisLabel: {
              interval: 75,
              color: "#6495ED",
              color: "#ffffff",
              textStyle: {
                fontSize: 13,
              },
              showMaxLabel: true,
            },
            axisTick: {
              show: true,
              inside: true,
            },
            axisLine: {
              lineStyle: {
                color: "#6495ED",
              },
            },
            //x坐标轴
            data: XAxias,
          },
          //y坐标轴
          yAxis: {
            type: 'value',
            min: -6,
            max: 6,
            interval: 2,
            axisLabel: {
              
              textStyle: {
                fontSize: 13,
                color: "#ffffff",
              },
              color: "#6495ED",
              formatter: function (value, index) {
                return value;
              },
            },
            axisTick: {
              inside: true,
              show: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#6495ED",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#6495ED",
              },
            },
            type: "value",
            scale: true,
            // min:function(value){return (value.min-(value.max-value.min)).toFixed(4)},
            // max:function(value){return (value.max+(value.max-value.min)).toFixed(4)}
          },
          dataZoom: [
            {
              type: "slider",
              show: false,
            },
          ],
          // 存放数据
          series: Series.slice(0, resData.num[0].num * 2),
        };
        let that = this;
        that.chart = that.$echarts.init(that.$refs.shishiChart, {
          useUTC: false,
        });
        that.chart.clear();
        that.chart.setOption(option);
        that.chart
          .off("legendselectchanged")
          .on("legendselectchanged", function (params) {
          //  console.log("params");
           // console.log(params);
            Selected = params.selected;
            // var name = params.name
            // if(this.Selected[])
            // this.Selected[name] = selected[name]
            option.legend.selected = Selected;
            that.chart.setOption({
              legend: {
                selected: Selected,
              },
            });
          });
      }else {
        option = {
          animation: false,
          //折线图标题
          title: {
            text: `${sensor}(${unit})/实时`,
            left: "center",
            top: "10",
            textStyle: {
              lineHeight: 30,
              color: "#ffffff",
              fontFamily: "xkfont",
              fontSize: 22,
            },
          },
          // 折线图线条的颜色
          color: Color.slice(0, resData.num[0].num),
          // 折线图的线条代表意义
          legend: {
            itemWidth: 10, //小圆点的宽度
            itemGap: 6,
            width: 500,
            inactiveColor: "#fff",
            activeColor: "#11EE96",
            textStyle: {
              color: Color.slice(0, resData.num[0].num),
            },
            selectedModel: "single", //折线可多选
            data: Data.slice(0, resData.num[0].num),
            selected: Selected,
            bottom: optionBottom,
            left: optionLeft,
            z: 100,
          },
          // 刻度
          grid: {
            left: "4%",
            right: "7%",
            bottom: "20%",
            top: "20%",
            containLabel: true,
            show: true,
            // borderColor: 'rgba(0, 240, 255, 0.3)'
          },
          // 悬浮图标
          tooltip: {
            show: true,
            trigger: "axis",
            //这一步的效果是当你的光标在左边的时候，悬浮标题在右边，在右边的时候，悬浮标题在左边
            position: function (pos, params, dom, rect, size) {
              var obj = { top: 60 };
              obj[["left", "right"][+(pos[0] < size.viewSize[0] / 2)]] = 5;
              return obj;
            },
          },
          //x坐标轴
          xAxis: {
            type: "category",
            axisLabel: {
              interval: 75,
              color: "#6495ED",
              color: "#ffffff",

              textStyle: {
                fontSize: 13,
              },
              showMaxLabel: true,
            },
            axisTick: {
              show: true,
              inside: true,
            },
            axisLine: {
              lineStyle: {
                color: "#6495ED",
              },
            },
            //x坐标轴
            data: XAxias,
          },
          //y坐标轴
          yAxis: {
            axisLabel: {
              textStyle: {
                fontSize: 13,
                color: "#ffffff",
              },
              color: "#6495ED",
              formatter: function (value, index) {
                return value;
              },
            },
            axisTick: {
              inside: true,
              show: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#6495ED",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#6495ED",
              },
            },
            type: "value",
            scale: true,
            // min:function(value){return (value.min-(value.max-value.min)).toFixed(4)},
            // max:function(value){return (value.max+(value.max-value.min)).toFixed(4)}
          },
          dataZoom: [
            {
              type: "slider",
              show: false,
            },
          ],
          // 存放数据
          series: Series.slice(0, resData.num[0].num * 2),
        };
        let that = this;
        that.chart = that.$echarts.init(that.$refs.shishiChart, {
          useUTC: false,
        });
        that.chart.clear();
        that.chart.setOption(option);
        that.chart
          .off("legendselectchanged")
          .on("legendselectchanged", function (params) {
          //  console.log("params");
           // console.log(params);
            Selected = params.selected;
            // var name = params.name
            // if(this.Selected[])
            // this.Selected[name] = selected[name]
            option.legend.selected = Selected;
            that.chart.setOption({
              legend: {
                selected: Selected,
              },
            });
          });
      }
      
      let flag = true;
      let sbsize = 5;
      // 实时数据
      this.time = setInterval(() => {
        // console.log('实时数据', resData);
        // console.log(this.chart);
        // console.log('this.Selected');
        // console.log(Selected);
        // this.chart.clear()
        this.chart.setOption(option);
        option.xAxis.data.shift();
        for (let i = 0; i < resData.num[0].num; i++) {
          if(sensorName === "temperature" && resData.num[0].num==2){
           // option.series[2*i].data.shift();
           // option.series[2*i+1].data.shift();
          }else if(sensorName === "settlement" && this.homeInfo.id==8){
            option.series[i].data.shift();
          }
          else{
            option.series[i].data.shift();
          }
          
          if (sensorName === "temperature") {
            if(resData.num[0].num == 2){
              option.series[2*i ].data.shift();
              option.series[2*i+1 ].data.shift();
            }else{
              option.series[i + 1].data.shift();
            }
            
          } else if (sensorName === "inclination" ||sensorName === "acceleration") {
            let i2 = i + resData.num[0].num;
            option.series[i2].data.shift();
          }
        }
        option.dataZoom.start = 0;
        option.dataZoom.end = 10;
        this.chart.clear();
        this.chart.setOption(option, true);
        let newDate = new Date();
        var newHours = newDate.getHours();
        if (newHours < 10) {
          newHours = "0" + newHours;
        }
        var newMinutes = newDate.getMinutes();
        if (newMinutes < 10) {
          newMinutes = "0" + newMinutes;
        }
        var newSeconds = newDate.getSeconds();
        if (newSeconds < 10) {
          newSeconds = "0" + newSeconds;
        }
        option.xAxis.data.push(newHours + ":" + newMinutes + ":" + newSeconds);
        if (flag) {
          sbsize = 5;
          flag = false;
        } else {
          sbsize = 8;
          flag = true;
        }
        for (let index = 0; index < resData.num[0].num; index++) {
          var splits = resData.sensorData[0].data.split("\t");
          // console.log('huoqushishi',splits);
          if (splits[index].search("_") != -1) {
            var s1 = splits[index].split("_");
            if (s1.length > 0) {
              if(sensorName === "temperature" && resData.num[0].num==2){
                
              }else{
                if(sensorName === "settlement" && this.homeInfo.id===8){
                    
                    option.series[index].data.push({
                    value: s1[1],
                    symbol: "circle",
                    symbolSize: sbsize,
                  });
                }else{
                  option.series[index].data.push({
                    value: s1[0],
                    symbol: "circle",
                    symbolSize: sbsize,
                  });
                }
                
              }
              
              if (sensorName === "inclination" ||sensorName === "acceleration") {
                option.series[index + resData.num[0].num].data.push({
                  value: s1[1],
                  symbol: "circle",
                  symbolSize: sbsize,
                });
              } else if (sensorName === "wind") {
                this.windDir = s1[1];
              } else if (sensorName === "temperature") {
                if(resData.num[0].num==2){
                  option.series[2*index ].data.push({
                  value: s1[1],
                  symbol: "circle",
                  symbolSize: sbsize,
                });
                option.series[2*index +1].data.push({
                  value: s1[0],
                  symbol: "circle",
                  symbolSize: sbsize,
                });
                }else{
                  option.series[index + 1].data.push({
                  value: s1[1],
                  symbol: "circle",
                  symbolSize: sbsize,
                });
                }
                
              }
            }
          } else {
            option.series[index].data.push({
              value: splits[index],
              symbol: "circle",
              symbolSize: sbsize,
            });
          }
        }
        this.chart.clear();
        this.chart.setOption(option, true);
        setTimeout(() => {
          for (let index = 0; index < resData.num[0].num; index++) {
            if(sensorName === "temperature" && resData.num[0].num == 2){
             // var q = option.series[2*index].data.pop();
             // option.series[2*index].data.push(q.value);
             // var q2 = option.series[2*index+1].data.pop();
             // option.series[2*index+1].data.push(q2.value);
            }else{
              var q = option.series[index].data.pop();
              option.series[index].data.push(q.value);
             // console.log(option)
            }
            
            if (sensorName === "inclination" ||sensorName === "acceleration") {
              var i = index + resData.num[0].num;
              var q1 = option.series[i].data.pop();
              option.series[i].data.push(q1.value);
            } else if (sensorName === "temperature") {
              if(resData.num[0].num==2){
                var q1 = option.series[2*index].data.pop();
                option.series[2*index].data.push(q1.value);

                var q2 = option.series[2*index+1].data.pop();
                option.series[2*index+1].data.push(q2.value);
              }else{
                var i = index + 1;
                var q1 = option.series[i].data.pop();
                option.series[i].data.push(q1.value);
              }
              
            }
          }
        }, 100);
      }, 1000);
      // clearInterval(time)
      return chart;
    },
    // 点击显示异常信息
    async yichang() {
      //("点击了异常", this.errorInfo);
      this.showData = false;
      this.showError = true;
      if (this.time !== 0) {
        clearInterval(this.time);
      }
      if (this.webSocket) {
        await this.webSocket.invoke('CloseTimer',this.serverTimer).catch(function(err){console.error(err.toString())})
        this.webSocket.stop();
        this.webSocket = null;
      }
      /*
      let url = `ws://43.138.244.242:8090/websocketPCError?itemId=${this.homeInfo.id}`;
      if (!this.webSocket) {
        this.webSocket = new WebSocketWrapper(url);
      }*/
      let resData;
      
      if (!this.webSocket) {
        //this.webSocket = new WebSocketWrapper(url);
         this.webSocket = new HubConnectionBuilder().withUrl(this.webSocketUrl,{withCredentials:false}).build()
      }
      
      try {
        //await this.webSocket.open();
       await this.webSocket.start().then(()=>{
        //let webSocketData = {"itemid":this.homeInfo.id}
        this.webSocket.invoke('SendMessageErrorAsync',this.homeInfo.id).catch(function(err){console.error(err.toString())})
      }).catch(err=>console.log(err))
        const response = await new Promise((resolve) => {
          // 接收一次消息后结束
          this.webSocket.on('ReceiveErrorMessage',ReceiveData=>{
            resData = ReceiveData.error
           // console.log("预警数据",resData)
            this.errorInfo = resData
            this.errorInfo.forEach((item, index) => {
            let time = this.timestampToTime(item.create_time);
            item.time = time;
          });
          })
        });

      } catch (error) {
        console.error(error);
      }
      /*
      await this.webSocket.open();
      console.log("WebSocket connection established!");

      const response = await new Promise((resolve) => {
        // 接收一次消息后结束
        this.webSocket.socket.onmessage = (event) => {
          resData = JSON.parse(event.data);
          console.log("错误信息", resData);
          this.errorInfo = resData.Error;
          this.errorInfo.forEach((item, index) => {
            let time = this.timestampToTime(item.create_time);
            item.time = time;
          });
          this.webSocket.send(true);

          this.webSocket.socket.onmessage = (event) => {
            console.log("true后", event.data);
            if (event.data == "true") {
              this.successStatus = true;
              this.closeStatus = false;
            } else {
              this.successStatus = false;
              this.closeStatus = true;
              resData = JSON.parse(event.data);
              this.errorInfo = resData.Error;
              this.errorInfo.forEach((item, index) => {
                let time = this.timestampToTime(item.create_time);
                item.time = time;
              });
              console.log("resData后", resData);
            }
          };
        };
      });*/
    },
    // 返回上一个页面
    async back() {
     
      if(this.homeInfo.id >=10){
        //HomeViewWf
        this.$router.push({
        path: "/",
      });
      }else{
        this.$router.push({
        path: "/HomeView",
      });
      }
      
      if (this.time !== 0) {
        clearInterval(this.time);
      }
      if (this.webSocket) {
      //  console.log("返回注意关闭webSocket")
        await this.webSocket.invoke('CloseTimer',this.serverTimer).catch(function(err){console.error(err.toString())})
        this.webSocket.stop();
        this.webSocket = null;
      }
    },
    // 虚拟体验VR弹窗
    
    openVR() {
      this.$router.push({
        path: "/VRModel",
        query: {
          homeId: this.homeInfo.id,
        },
      });
    },
    resizeChart() {
      this.chart.resize();
    },
    
  },
  async destroyed() {
   // console.log("页面被销毁");
    if (this.time !== 0) {
      clearInterval(this.time);
    }
    if (this.webSocket) {
      await this.webSocket.invoke('CloseTimer',this.serverTimer).catch(function(err){console.error(err.toString())})
      this.webSocket.stop();
      this.webSocket = null;
    }
    window.removeEventListener("resize", this.resizeChart);
  },
};
</script>
<style lang="scss" scoped>
#DetailContainer {
  color: #d3d6dd;
  padding: 1.25rem 1rem 0 1rem;
  font-family: "xkfont";
  min-width: 1366px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 15px;
  height: 100vh;
  background-image: url("../assets/bg.jpg");
  background-size: cover;
  background-position:center;
}

  
.homeDeatil {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 55px;
  height: 80%;
  .dv-border-box-10 {
    margin-bottom: 1rem;
  }
  .homeDetailContent {
    width: 100%;
    display: flex;
    margin-bottom: 0;
    .homeDetailLeft {
      width: 15%;
      height: 100%;
      .sensorTxt {
        width: 100%;
        height: 100%;
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .sensorTxt:hover {
        cursor: pointer;
      }
    }
    .homeDetailRight {
      width: 85%;
      display: flex;
      // justify-content: space-between;
      padding-top: 1.5rem;
      .homeDetailRight1 {
        width: 33%;
        .dv-border-box-10 {
          height: 18rem;
          .border-box-content {
            .electronInfo {
              padding-top: 1rem;
            }
            .scrollable-div {
              width: 100%;
              height: 16rem;
              padding: 1.1rem 0.8rem;
              font-size: 1.1rem;

              div {
                width: 100%;
                height: 16rem;
                text-indent: 2em;
              }
            }
          }
        }
      }
      .homeDetailRight2 {
        display: flex;
        width: 67%;
        padding-right: 1rem;
        .homeDetailRight2Data {
          width: 49.1%;
          height: 18rem;
          .shishi {
            width: 100%;
            height: 100%;
            .shishiChart {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .homeDetailRight3 {
        width: 64.9%;
        height: 37rem;
        margin-right: 1rem;
        .homeDetailStatus {
          height: 20%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2rem;
        }
        .homeDetailTable {
          h1 {
            text-align: center;
          }
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }
  .back {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .INCPosition {
    position: absolute;
    top: 4.2%;
    right: 12%;
    line-height:0.5rem;
    font-family: Arial, Helvetica, sans-serif;
  }
  .WINPosition {
    position: absolute;
    top: 10%;
    right: 5%;
    font-size: 1.1rem;
    font-weight: 700;
    display: flex;
    p{
      width: 4rem;
    }
  }

  .back:hover {
    cursor: pointer;
  }
}
.backButton {
  width: 11rem;
  height: 3.3rem;
  font-size: 1.4rem;
  line-height: 3.3rem;
  text-align: center;
}

::v-deep .el-alert {
  background-color: transparent !important;
}
::v-deep .el-alert__content {
  color: #ffffff;
}
::v-deep .el-alert--warning.is-light .el-alert__description {
  color: #fff;
}
::v-deep .el-alert .el-alert__description {
  font-size: 1rem;
}
::v-deep .el-alert__title {
  font-size: 1.25rem;
}
::v-deep .el-table__empty-text {
  font-size: 1.4rem;
}
// 异常信息表格
/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
  color: aliceblue !important;
}

/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent !important;
  border: none; //去除表格
  font-size: 1.2rem;
}

/*去除底边框*/
::v-deep.el-table td.el-table__cell {
  border: none;
}

::v-deep.el-table th.el-table__cell.is-leaf {
  border: none;
}

/*去除底部灰条.el-table::before*/
::v-deep .el-table::before {
  display: none;
}
::v-deep .el-table--border th.el-table__cell {
  border-bottom: none !important;
}

// 修改表格文字颜色
::v-deep .el-table thead {
  color: aliceblue;
}
// 滚动条的宽度
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 2px; // 横向滚动条
  height: 8px; // 纵向滚动条 必写
}
// 滚动条的滑块
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
}
// 滚动条上面的
::v-deep .el-table__fixed-right-patch {
  background-color: transparent !important;
  border-bottom: 0 !important;
}
</style>
